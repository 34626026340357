<template>
  <div>
    <div v-if="quren == 1" class="panel demoWrapper">
      <div class="tabtitle">
        <div class="title-left">
          <span>Enterprise Management</span>
        </div>
      </div>
      <div class="search-form">
        <div style="display: flex; justify-content: space-between">
          <el-form
            :model="formLabelAlign"
            :label-position="labelPosition"
            :inline="true"
          >
            <el-form-item label="Enterprise Number" prop="id">
              <el-input
                v-model.trim="formLabelAlign.id"
                size="small"
                placeholder="Please Enter"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                maxlength="9"
              />
            </el-form-item>

            <el-form-item label="Enterprise Name" prop="enterpriseName">
              <el-input
                v-model.trim="formLabelAlign.enterpriseName"
                size="small"
                placeholder="Please enter the content"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="License Type">
              <el-select
                v-model.trim="formLabelAlign.licenseType"
                placeholder="Please Enter"
                clearable
              >
                <!-- Business License -->
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                <!-- <el-option label="Business License" value="0" /> -->
              </el-select>
            </el-form-item>

            <el-form-item label="License Number">
              <el-input
                v-model.trim="formLabelAlign.licenseNumber"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item v-show="boxShow" label="Validity Type">
              <el-select
                v-model.trim="formLabelAlign.state"
                placeholder="Please Choose"
                clearable
              >
                <el-option
                  v-for="item in validityType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div class="buttom-top">
            <el-button size="small" @click="reset"> Reset </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="searchBtn()"
            >
              Search
            </el-button>
          </div>
        </div>
        <div class="add">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            class="add-button"
            @click="addEnterprise"
          >
            Add
          </el-button>
        </div>
      </div>
      <div class="panel-body">
        <el-table
          class="tableBox"
          :data="tableData"
          :header-cell-style="{ background: '#EBF1FF' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column prop="id" label="Enterprise Number" min-width="170">
            <template slot-scope="scope">
              <el-link
                type="primary"
                size="small"
                :underline="false"
                @click="goLink(scope.row)"
              >
                {{ scope.row.id || '--' }}
              </el-link>
            </template>
          </el-table-column>

          <el-table-column
            prop="enterpriseName"
            label="Enterprise Name"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.enterpriseName }}
            </template>
          </el-table-column>

          <el-table-column
            prop="licenseType"
            label="License Type"
            min-width="154"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.licenseType == 0"> Business License </font>
            </template>
          </el-table-column>

          <el-table-column
            prop="licenseNumber"
            label="License Number"
            min-width="154"
          />
          <el-table-column
            label="Basic Info Audit Status"
            prop="baseInfoAuditState"
            min-width="170"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.baseInfoAuditState == 1">
                <i class="el-icon-success" style="color: #40c740" />Passed
              </font>
              <font v-else-if="scope.row.baseInfoAuditState == 0">
                <i class="el-icon-time" style="color: black" />Waiting Audit
              </font>
              <font v-else>
                <i class="el-icon-error" style="color: #fa5050" />Rejected
              </font>
            </template>
          </el-table-column>
          <el-table-column
            label="VAT Info Audit Status"
            min-width="170"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.invoiceInfoAuditState == 1">
                <i class="el-icon-success" style="color: #40c740" />Passed
              </font>
              <font v-else-if="scope.row.invoiceInfoAuditState == 0">
                <i class="el-icon-time" style="color: black" />Waiting Audit
              </font>
              <font v-else>
                <i class="el-icon-error" style="color: #fa5050" />Rejected
              </font>
            </template>
          </el-table-column>
          <el-table-column
            label="Bank Info Audit Status"
            min-width="170"
          >
            <template slot-scope="scope">
              <font v-if="scope.row.bankInfoAuditState == 1">
                <i class="el-icon-success" style="color: #40c740" />Passed
              </font>
              <font v-else-if="scope.row.bankInfoAuditState == 0">
                <i class="el-icon-time" style="color: black" /> Waiting Audit
              </font>
              <font v-else>
                <i class="el-icon-error" style="color: #fa5050" />Rejected
              </font>
            </template>
          </el-table-column>
          <el-table-column label="Validity Type" prop="state" min-width="120">
            <template slot-scope="scope">
              <i
                :ref="scope.$index"
                :class="
                  scope.row.state == 0 ? 'el-icon-success' : 'el-icon-error'
                "
                :style="
                  scope.row.state == 0 ? 'color:#40C740' : 'color:#FA5050'
                "
                style="margin-right: 5px"
              />
              <font v-if="scope.row.state == 0"> Valid </font>
              <font v-else-if="scope.row.state == 1">Invalid</font>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            prop="name"
            label="Operate"
            width="200"
          >
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="viewContract(scope.row)">
                View Contract
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="margin-left: 0"
                @click="addContract(scope.row)"
              >
                Add Contract
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div v-if="quren == 2">
      <Businesslist :obj="obj" @get-quren="parentQuren" />
    </div>
  </div>
</template>

<script>
// import 'element-ui/lib/theme-chalk/index.css';
// import {panelTitle} from 'components'
import Businesslist from './Businesslist.vue' //企业信息修改组件
import { getBannerList } from '@/api/enterprise/enterprise';
export default {
  components:{
    Businesslist
  },
  data() {
    return {
      emptyImgSrc: '',
      loading: false,
      quren: '1',
      obj: {},
      input3: '', //  判断name1/name2   为name1时 赋值 筛选框 企业名称   为name2 为企业编号
      labelPosition: 'top',
      formLabelAlign: {
        licenseType: '',
        baseInfoAuditState: '',
        state: ''
      },

      select: {},
      boxShow: true,
      title: '', //  点击添加/edit传给子组件的标题名称
      input1: '', //  筛选中合同ID
      value: '', //  筛选中状态
      date1: '',
      currentPage: 1,
      pagesize: 10,
      // size:20,   // 每页多少条
      // total:20,  // 多少条数据

      // ValidityType
      validityType: [
        {
          value: 0,
          label: 'Valid'
        },
        {
          value: 1,
          label: 'Invalid'
        }
      ],
      discountTypeList: [
        {
          value: 0,
          label: 'Waiting Audit'
        },
        {
          value: 1,
          label: 'Passed'
        },
        {
          value: 2,
          label: 'Rejected'
        }
      ],
      tableData: [],
      total: 0,
      options: [
        {
          value: '0',
          label: 'Business License'
        }
      ]
    };
  },
  created() {
    this.getDataList();
    this.emptyImgSrc = '';
  },
  mounted() {},
  methods: {
    selectval(val) {},
    formLabelId() {
      this.formLabelAlign.id = this.input3;
    },
    // 展开收起筛选部分2
    businessShow() {
      this.boxShow = !this.boxShow;
    },
    // 合同号particulars页面跳转
    goLink(row) {
      this.obj = row;
      this.quren = '2';
    },
    // 新增企业信息
    addEnterprise() {
      this.$router.push('/businessmanagement/createEnterprise');
    },
    // 合同页
    viewContract(row) {
      if (!row.contractNo) {
        this.$message({message:'There are no contracts to view'})
        // row.flang = 'look';
        // this.$router.push({
        //   path: '/contractManage/addContract',
        //   query: { flang:row.flang,enterpriseName:row.enterpriseName,id:row.id,contractNo:row.contractNo}
        // });
      } else {
        row.flang = 'quer';
        this.$router.push({
          path: '/contractManage/contractList',
          query: {contractNo:row.contractNo,id:row.id}
        });
      }

      // 赋值给表单--
    },
    // 子组件点击返回传给父组件的quren
    parentQuren(data) {
      this.$nextTick(() => {
        this.quren = data;
      });
      this.getDataList();
    },
    // 重置搜索筛选条件
    reset() {
      this.formLabelAlign = {};
      this.pagesize=10;
      this.currentPage=1;
      this.getDataList();
      // 加载列表------------111
    },
    // 搜索
    searchBtn() {
      this.tableData = [];
      // 调用查询筛选接口------------
      this.getDataList()
      // getBannerList(this.formLabelAlign).then(res => {
      //   if (res.code === 200) {
      //     this.tableData = res.data.dataList;
      //     this.total = res.data.totalCount;
      //   }
      // });
    },
    // 页面查询接口
    getDataList() {
      // 调用查询筛选接口-------------
      let par = {enterpriseInfoDTO: {...this.formLabelAlign}, pageSize: this.pagesize, pageNo: this.currentPage };
      getBannerList(par).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.total = res.data.totalCount;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 获取折扣率类型下拉框选项值-------------
    getDiscountTypeList() {},

    // 导出---------------
    exportState() {},
    // 合同只能输入字母加数字
    accountInput(val) {
      let codeReg = new RegExp('[A-Za-z0-9]+'); //正则：字母加数字组合
      let len = val.length;
      let str = '';
      for (let i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i];
        }
      }
      this.input1 = str;
    },

    // 跳转添加合同信息
    addContract(row) {
      // 请勿修改该变量 enterprise；跳转合同的时候需要判断变量是否为 enterprise
      row.flang = 'enterprise';
      this.$router.push({
        path: '/contractManage/addContract',
        query: { flang:row.flang,enterpriseName:row.enterpriseName,id:row.id}
      });
      // if (row.contractNo !== null) {
      //   row.flang = 'look';
      //   this.$router.push({
      //     name: 'Create a contract',
      //     path: '/contractManage/addContract',
      //     query: { flang:row.flang,enterpriseName:row.enterpriseName,id:row.id}
      //   });
      // } else {
      //   row.flang = 'enterprise';
      //   this.$router.push({
      //     path: '/contractManage/addContract',
      //     query: { flang:row.flang,enterpriseName:row.enterpriseName,id:row.id}
      //   });
      // }
    },
    // 分页器
    handleSizeChange(val) {
      this.pagesize = val; //console.log(`每页 ${val} 条`);
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.currentPage = val; //console.log(`当前页: ${val}`);
      this.getDataList();
    },
    loadshowBtn() {
      this.Loadshow = !this.Loadshow;
    }
  }
};
</script>
<style lang="less" scoped>
.demoWrapper {
  /deep/ .el-pagination__editor.el-input {
    width: 50px;
    margin-right: 0;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }

  .tabtitle {
    position: relative;
    height: 68px;
    line-height: 68px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    .title-left {
      position: absolute;
      left: 0px;
      padding-left: 16px;
      font-size: 20px;
      font-weight: Bold;
      color: rgba(36, 37, 38, 1);
    }
  }
  .search-form {
    width: 100%;
    height: 100%;
    padding: 24px 16px 0;
    font-size: 14px;
    line-height: 0;
    /deep/ .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .buttom-top {
      // width: 40%;
      height: 110px;
      line-height: 110px;
      padding-top: 6px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        width: 100px;
        height: 32px;
        border: 1px solid rgba(199, 204, 210, 1);
        border-radius: 4px;
        margin-left: 14px;
        font-size: 14px;

        ::v-deep .el-icon-search {
          font-size: 14px;
        }
      }
      .qihuan {
        border: 0;
      }
      .qihuan {
        border: 0;
      }
    }

    /deep/.el-form-item {
      width: 200px;
      margin-left: 20px;
      margin-right: 20px;
      .input-with-select {
        .input-with-select {
          // height: 116%;
          font-size: 11px;
        }
      }
      /deep/.el-form-item__content {
        height: 32px;
        line-height: 32px;
      }
    }
    /deep/.el-form--label-top .el-form-item__label {
      padding: 0;
      /deep/.el-select > .el-input__inner {
        padding: 0px;
      }
    }
  }
  /deep/.el-input-group__prepend div.el-select .el-input__inner {
    padding: 0px;
  }
  .panel-body {
    padding: 0 16px;
  }
}
/* /deep/ .el-table__body {
  border-collapse: separate;
  border-spacing: 0 8px;
  table-layout: auto !important;
} */

// 表格无数据显示样式
.data-pic-img {
  width: 100%;
  height: 238px;

  margin: 0 auto;
  .data-pic {
    width: 92px;
    height: 92px;
    position: relative;
    top: 50%; /*偏移*/
    transform: translateY(-50%);
    .No-message {
      padding-top: 35px;
    }
  }
}
/deep/.el-input .el-input--suffix {
  width: 86px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
  width: 100px;
}
::v-deep .el-link--primary {
  color: #2262ff;
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}

.add {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}
</style>
